// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/Users/kations/Projekte/Spicy/spicy-next/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-service-js": () => import("/Users/kations/Projekte/Spicy/spicy-next/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-work-js": () => import("/Users/kations/Projekte/Spicy/spicy-next/src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-templates-contact-js": () => import("/Users/kations/Projekte/Spicy/spicy-next/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-imprint-js": () => import("/Users/kations/Projekte/Spicy/spicy-next/src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-pages-404-js": () => import("/Users/kations/Projekte/Spicy/spicy-next/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

