import React, { useState, Fragment } from "react"
import { Link } from "gatsby"
import Logo from "../images/spicy.svg"

import { Icon, styled, Overlay } from "unikit"

const Header = styled.View({
  position: "absolute",
  width: "100%",
  height: 100,
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  zIndex: 2000,
  paddingHorizontal: 30,
})

export default () => {
  const [visible, setVisible] = useState(false)

  const NavLink = ({ to, children }) => {
    return (
      <Link
        to={to}
        style={{ color: "rgba(255,255,255,0.6)", fontSize: 40, marginTop: 30 }}
        activeStyle={{ color: "#FFF" }}
        onClick={() => setVisible(false)}
      >
        {children}
      </Link>
    )
  }
  return (
    <Fragment>
      <Header>
        <Link to="/">
          <img
            src={Logo}
            alt="Spicy Media"
            style={{ width: "auto", height: 70 }}
          />
        </Link>

        <Icon
          onPress={() => {
            setVisible(!visible)
          }}
          name={visible === true ? "x" : "burger"}
          size={60}
          color={visible === true ? "#FFF" : undefined}
        />
      </Header>

      <Overlay
        type="primary"
        visible={visible}
        onClose={() => setVisible(false)}
        position="right"
        width={400}
        height="100%"
        portal={false}
        style={{ borderRadius: 0, justifyContent: "center", paddingLeft: 30 }}
      >
        <NavLink to="/">Home</NavLink>
        <NavLink to="/service">Service</NavLink>
        <NavLink to="/work">Work</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </Overlay>
    </Fragment>
  )
}
