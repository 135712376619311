import { ThemeProvider } from "unikit"
import React from "react"
import Layout from "./src/components/Layout"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <ThemeProvider
      theme={{
        colors: {
          primary: "#F69A37",
          dark: "#231c20",
          text: "#231c20",
          background: "#FFF",
        },
        fontSize: { h1: "3.8rem", h2: "2.8rem", h3: "2rem", p: 18 },
        globals: { roundness: 30 },
        wrapperWidth: 1200,
      }}
    >
      {element}
    </ThemeProvider>
  )
}
