import React, { useState } from "react"

import { UniProvider, styled } from "unikit"
import "./layout.css"
import Header from "./Header"
import Seo from "./Seo"

const Layout = styled.View({
  display: "block",
  minHeight: "100vh",
  backgroundColor: "background",
})

export default ({ children, pageContext = {} }) => {
  return (
    <Layout>
      <Seo {...pageContext} />
      <Header />

      {children}
    </Layout>
  )
}
